// Styling for the masthead
header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - #{$navbar-height});
  // replace the masthead.jpg image in the /img directory to change the image
  // if the image name change, make sure to update the url path below!
  // background: 
  // linear-gradient(to bottom, #{fade-out($black, 1)} 0%,#{fade-out($black, 0.6)} 100%),
  background: url('../images/headshot.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    font-size: 70px;
    font-weight: 300;
  }
  .btn {
    margin: 1px 1px 1px 16px;
    padding: 12px 30px 12px 30px;
    font-size: 12px;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
    min-height: 40rem;
    padding-top: $navbar-height;
    padding-bottom: 0;
    p {
      font-size: 1.15rem;
    }
  }
  @include media-breakpoint-up(xl) {
  }
  .container {
    text-align: left;
  }
  .title {
    font-size: 14px;
  }
}
