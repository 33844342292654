// Base styling for the navbar
#mainNav {
  box-shadow: $box-shadow;
  background-color: $white;
  transition: background-color 0.2s ease;
  .navbar-brand {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    color: $gray-900;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $gray-600;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        font-size: 0.9rem;
        padding: 0.75rem 0;
        &:hover,
        &:active {
          color: $primary;
        }
        &.active {
          color: $primary !important;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    // Base styling for the navbar - screen sizes greater than the large breakpoint
    box-shadow: none;
    background-color: transparent;
    .navbar-brand {
      color: fade-out($black, .3);
      &:hover {
        color: $black;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: fade-out($black, .3);
          padding: 0 1rem;
          &:hover {
            color: $black;
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
      }
    }
    // Navbar styling applied when the page is scrolled
    &.navbar-scrolled {
      box-shadow: $box-shadow;
      background-color: $white;
      .navbar-brand {
        color: $gray-900;
        &:hover {
          color: $primary;
        }
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $gray-900;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
