// Import custom variables (overrides Bootstrap default variables)
@import "variables.scss";

// Import Bootstrap
@import "bootstrap/scss/bootstrap";

// Import custom styles
@import "global.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "portfolio.scss";
