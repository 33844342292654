// Global styling for this template
body,
html {
  width: 100%;
  height: 100%;
  scroll-padding-top: 100px;
}

// Typography
.text-white-75 {
  color: fade-out($white, .25);
}

// Custom horizontal rules
hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}

// Button restyling
.btn {
  font-family: $font-family-sans-serif;
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

// Page section padding
.page-section {
  padding: 8rem 0;
}

.site-section {
  padding: 20px 55.5px 20px 55.5px;
  line-height: 1.6;
  margin-left: auto;
}
.site-section-alternate {
  background-color: #E2E2E2;
}

.site-section .section-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}
.header-holder {
  text-align: center;
}
.header-holder h2 {
  padding: 0 120px 30px 120px;
}
a {
  color: #0600E2;
}

.simple-page-container {
  padding-top: 60px;
}

ul.activityTypes {
  list-style: none;

}
.bibliography > li > p {
  display: inline;
}
