// Styling for the portfolio section
#portfolio {
  .container-fluid {
    max-width: 1920px;
    .portfolio-box {
      position: relative;
      display: block;
      .portfolio-box-caption {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        width: 100%;
        height: 100%;

        position: absolute;
        bottom: 0;

        text-align: center;
        opacity: 0;

        color: $white;
        background: fade-out($primary, .1);
        transition: opacity 0.25s ease;
        text-align: center;
        .project-category {
          font-family: $font-family-sans-serif;
          font-size: 0.85rem;
          font-weight: 600;
          text-transform: uppercase;
        }
        .project-name {
          font-size: 1.2rem;
        }
      }
      &:hover {
        .portfolio-box-caption {
          opacity: 1;
        }
      }
    }
  }
}
